.main {
  height: 100%;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.value {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.button {
  appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.textbox {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 8px;
}

.button:hover, .button:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.button:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.asyncButton {
  composes: button;
  position: relative;
  margin-left: 8px;
}

.asyncButton:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.asyncButton:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s
}


.epTableTitleRow {
  color: rgb(68, 96, 138);
  font-weight:bold;
 /* font-size:12pt;*/
  padding:5 5 5 5;
  margin:0 5 5 0;
  background-color:rgb(197, 197, 180);
  display: block;/* table-row not filling background space*/
  width: 100%;
}

 

.epTable {
    display: table;
    width: calc(100% - 10px);
/*    border: 1px solid blue; */
/*    padding: 5px; */
}

.epTableRow {
  display: table-row;
}

.epTableRowButton {
  width:100%; 
  text-align:left; 
  font-size:inherit;
}

.scrollTable {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);
}

.scrollTable > .epTableRow {
  background-color:rgb(247, 247, 238);
}

.scrollTable >  .epTableRow:hover {
  filter: brightness(95%);
/*   background-color: rgb(232, 231, 231, 0.3);*/
}

.scrollTable > .epTableRowMarked {
  filter: brightness(90%);
  /*    background-color: rgb(212, 211, 211, 0.5);*/
}

.epTableCell {
  display: table-cell;
  font-size: 14px;
  width: 100%;
  text-align: left;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

.alternateBackground {
  background: #f0f0f0;
}
.defaultBackground {
  background: white;
}